import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../../components/hero'
import Layout from '../../components/layout'


class RootIndex extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const projects = get(this, 'props.data.allContentfulProject.edges')
        const [author] = get(this, 'props.data.allContentfulPerson.edges')

        return (
            <Layout location={this.props.location}>
                <div style={{ background: '#fff' }}>
                    <Helmet title={siteTitle} />
                    <Hero data={author.node} />
                    <div className="wrapper">
                        <p>{author.node.bio.bio}</p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: author.node.bio.childMarkdownRemark.html,
                            }}
                        />

                        <h2 className="section-headline">Recent projects</h2>
                        <section className="project">
                                    {projects.map(({node}) => {
                                        return(
                                            <div className={'project-item'} key={node.url}>
                                                <div className="flex column">
                                                    <p className="project-date small">
                                                        {node.dateStart}~
                                                        {node.dateEnd}
                                                    </p>
                                                </div>
                                                <p className="project-title bold medium">
                                                    {node.name}
                                                </p>
<div className="txt-box">
    {node.description}
</div>
                                                {node.url ? <a className={'external-link'} target="_blank" rel="noopener" href={`${node.url}`}>サイトを見る</a> :
                                                    <br/> }
                                            </div>
                                        )
                                    })}
                        </section>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default RootIndex

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulProject(sort: { fields: [dateEnd], order: DESC }) {
      edges {
        node {
          name
          url
          dateStart(formatString: "YYYY.MM.DD")
          dateEnd(formatString: "YYYY.MM.DD")
          description 
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          bio {
            childMarkdownRemark {
              html
            }
          }           
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "#d7e5e8"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
